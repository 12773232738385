import * as styles from "../../pages/magenta-loyalty-club.module.css";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";

import React from "react";

const ProductsFAQ = ({ props }: ProductsFAQProps) => {

  return (
    <div className={styles.faq1} >
      <div className={styles.list}>
        <Accordion
          w="100%"
          className={styles.list}
          defaultIndex={[0]}
          allowMultiple
        >
          {props.questionsrepeater?.map((item, index) => {
            return (
              <AccordionItem w="100%" key={index}>
                <div className={styles.br} />
                <AccordionButton
                  className={styles.q}
                  _hover={{ background: "transparent" }}
                >
                  <b className={styles.howDoI}>{item.question}</b>
                  <AccordionIcon
                    width="1.88rem"
                    height="1.88rem"
                    color="#DDA572"
                  />
                </AccordionButton>
                <AccordionPanel
                  className={styles.itsEasyIts}
                  dangerouslySetInnerHTML={{ __html: item.answer }}
                />
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

type ProductsFAQProps = {
  props: {
    questionsrepeater: [
      {
        question: string;
        answer: string;
      }
    ];
  };
};

export default ProductsFAQ;
