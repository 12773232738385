// extracted by mini-css-extract-plugin
export var ammount = "magenta-loyalty-club-module--ammount--3089e";
export var answerWrapper = "magenta-loyalty-club-module--answerWrapper--a4e72";
export var benefit = "magenta-loyalty-club-module--benefit--cc153";
export var benefit1 = "magenta-loyalty-club-module--benefit1--898b5";
export var benefitrow = "magenta-loyalty-club-module--benefitrow--1e59b";
export var br = "magenta-loyalty-club-module--br--1654b";
export var chevronUpIcon = "magenta-loyalty-club-module--chevronUpIcon--e17e2";
export var contactACustomer = "magenta-loyalty-club-module--contactACustomer--d91e0";
export var discoverProducts = "magenta-loyalty-club-module--discoverProducts--77171";
export var divider = "magenta-loyalty-club-module--divider--c5630";
export var earn = "magenta-loyalty-club-module--earn--d9190";
export var faq = "magenta-loyalty-club-module--faq--b6704";
export var faq1 = "magenta-loyalty-club-module--faq1--99b79";
export var feature = "magenta-loyalty-club-module--feature--44a33";
export var feature3 = "magenta-loyalty-club-module--feature3--1fcfc";
export var feature4 = "magenta-loyalty-club-module--feature4--72ec0";
export var headingLarge3xl = "magenta-loyalty-club-module--headingLarge3xl--cf5a2";
export var headingLarge3xlRedirect = "magenta-loyalty-club-module--headingLarge3xlRedirect--a683a";
export var heroContent = "magenta-loyalty-club-module--heroContent--34505";
export var heroText = "magenta-loyalty-club-module--heroText--efe07";
export var herobackgroundIcon = "magenta-loyalty-club-module--herobackgroundIcon--5fc81";
export var herosection = "magenta-loyalty-club-module--herosection--f920b";
export var howDoI = "magenta-loyalty-club-module--howDoI--213d3";
export var howItWorks = "magenta-loyalty-club-module--howItWorks--7834c";
export var howtocolum = "magenta-loyalty-club-module--howtocolum--193ae";
export var howtocolum2 = "magenta-loyalty-club-module--howtocolum2--a2041";
export var howtocolum3 = "magenta-loyalty-club-module--howtocolum3--e4729";
export var howtocolum4 = "magenta-loyalty-club-module--howtocolum4--a227f";
export var howtocolum5 = "magenta-loyalty-club-module--howtocolum5--bee7b";
export var howtorow = "magenta-loyalty-club-module--howtorow--d5862";
export var howtorow1 = "magenta-loyalty-club-module--howtorow1--ef5a8";
export var itsEasyIts = "magenta-loyalty-club-module--itsEasyIts--fb5a3";
export var lightButton = "magenta-loyalty-club-module--lightButton--ca465";
export var list = "magenta-loyalty-club-module--list--1c823";
export var loyaltysection1 = "magenta-loyalty-club-module--loyaltysection1--47243";
export var magentaloyaltyclub = "magenta-loyalty-club-module--magentaloyaltyclub--8dbcd";
export var magentasection = "magenta-loyalty-club-module--magentasection--44618";
export var member = "magenta-loyalty-club-module--member--01fdb";
export var orderonline1 = "magenta-loyalty-club-module--orderonline1--b1280";
export var orderphone1 = "magenta-loyalty-club-module--orderphone1--4a24e";
export var orderphone3 = "magenta-loyalty-club-module--orderphone3--4dab0";
export var overlay = "magenta-loyalty-club-module--overlay--2d279";
export var overlay1 = "magenta-loyalty-club-module--overlay1--3e3c4";
export var p = "magenta-loyalty-club-module--p--b1a87";
export var p1 = "magenta-loyalty-club-module--p1--f56cb";
export var points = "magenta-loyalty-club-module--points--5a573";
export var points2 = "magenta-loyalty-club-module--points2--7f449";
export var points3 = "magenta-loyalty-club-module--points3--dcd85";
export var pointstable = "magenta-loyalty-club-module--pointstable--166ad";
export var q = "magenta-loyalty-club-module--q--03415";
export var remixIconslinesystemarrow = "magenta-loyalty-club-module--remixIconslinesystemarrow--de843";
export var salestextbox = "magenta-loyalty-club-module--salestextbox--c5d50";
export var salestextbox1 = "magenta-loyalty-club-module--salestextbox1--558d4";
export var sectionText = "magenta-loyalty-club-module--sectionText--1e7b8";
export var sectionicon = "magenta-loyalty-club-module--sectionicon--28a90";
export var spend1 = "magenta-loyalty-club-module--spend1--41f0a";
export var spend12 = "magenta-loyalty-club-module--spend12--1a63c";
export var split = "magenta-loyalty-club-module--split--e0ad1";
export var starIcon = "magenta-loyalty-club-module--starIcon--2572c";
export var tier = "magenta-loyalty-club-module--tier--3bf36";
export var tier2 = "magenta-loyalty-club-module--tier2--97334";
export var tiercheck = "magenta-loyalty-club-module--tiercheck--5ff6c";
export var tiers = "magenta-loyalty-club-module--tiers--5e9b0";
export var tiertitle = "magenta-loyalty-club-module--tiertitle--0d110";
export var title = "magenta-loyalty-club-module--title--bdeef";
export var title1 = "magenta-loyalty-club-module--title1--e8722";
export var title3 = "magenta-loyalty-club-module--title3--19a37";
export var titlebox = "magenta-loyalty-club-module--titlebox--a5699";
export var titlebox1 = "magenta-loyalty-club-module--titlebox1--f35ec";
export var titlebox2 = "magenta-loyalty-club-module--titlebox2--56afe";
export var welcomeGift = "magenta-loyalty-club-module--welcomeGift--2ca6d";