import { graphql, useStaticQuery } from 'gatsby';

// PRODUCTS
export const fetchAllProducts = () => {
	const data = useStaticQuery(graphql`
		query {
			allWpSimpleProduct {
				totalCount
				nodes {
					name
					id
					slug
					databaseId
					image {
						sourceUrl
						altText
					}
					productTags {
						nodes {
							name
							id
							slug
						}
					}
					productCategories {
						cat: nodes {
							name
							id
							slug
						}
					}

					promotions {
						onPromotion
						additionalTag
					}

					... on WpSimpleProduct {
						id
						name
						price
						regularPrice
						onSale
						salePrice
					}
				}
			}

			allWpVariableProduct {
				totalCount
				nodes {
					name
					id
					slug
					databaseId
					image {
						sourceUrl
						altText
					}
					productTags {
						nodes {
							name
							id
							slug
						}
					}
					productCategories {
						cat: nodes {
							name
							id
							slug
						}
					}

					... on WpVariableProduct {
						id
						name
						price
						regularPrice
						onSale
						salePrice
					}
				}
			}
		}
	`);

	const simpleProducts = data.allWpSimpleProduct.nodes;
	const variableProducts = data.allWpVariableProduct.nodes;

	// Combine and return both sets of products
	return [...simpleProducts, ...variableProducts];
};
