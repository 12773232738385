import * as styles from "./grid-table.module.css";

import { Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/react"

import React from "react"

type GridTableProps = {
  data: any[];
};

const GridTable = ({ data }: GridTableProps) => {
  const shouldDisplayTable = data.some((item) => !!item.price);

  if (!shouldDisplayTable) {
    return null;
  }

  return (
    <div className={styles.gridTableContainer}>
      <TableContainer>
        <Table>
          <Thead style={{ backgroundColor: "#FCFCFC" }}>
            <Tr>
              <Th style={{ textTransform: "capitalize", fontSize: "14px" }}>
                Packs
              </Th>
              <Th style={{ textTransform: "capitalize", fontSize: "14px" }}>
                For each
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((item, index) => {
              if (!item.price) {
                return null;
              }
              const isEven = index % 2 === 0;
              return (
                <Tr key="index" style={{ backgroundColor: isEven ? "#FFFFFF" : "#FCFCFC" }} fontSize="14px">
                  <Td>{item.amount}</Td>
                  <Td>$ {item.price}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default GridTable;