// extracted by mini-css-extract-plugin
export var addButton = "product-card-module--addButton--0b9f8";
export var beloteroBalanceFront600x600Icon = "product-card-module--beloteroBalanceFront600x600Icon--1aee0";
export var button = "product-card-module--button--fcc7b";
export var button2 = "product-card-module--button2--d078b";
export var discount = "product-card-module--discount--7f1c7";
export var label = "product-card-module--label--44fb2";
export var popover = "product-card-module--popover--3cd70";
export var popoverMobile = "product-card-module--popoverMobile--b7110";
export var price = "product-card-module--price--8c5ee";
export var price1 = "product-card-module--price1--4eb26";
export var product = "product-card-module--product--a8c31";
export var productcard = "product-card-module--productcard--c014f";
export var productcardButton = "product-card-module--productcardButton--06092";
export var productcardMenu = "product-card-module--productcardMenu--0aec4";
export var productcardRelated = "product-card-module--productcardRelated--f246d";
export var productdetails = "product-card-module--productdetails--083e8";
export var productname = "product-card-module--productname--9236e";
export var relatedprice = "product-card-module--relatedprice--ee479";
export var relatedproduct = "product-card-module--relatedproduct--95187";
export var sale = "product-card-module--sale--4666c";
export var shoppingCartPlusIcon = "product-card-module--shoppingCartPlusIcon--38e05";
export var simpleGrid = "product-card-module--simpleGrid--686ad";