import * as React from 'react';
import * as styles from './product-card.module.css';

import {
	AspectRatio,
	Box,
	Button,
	HStack,
	Image,
	Link,
	Skeleton,
	Stack,
	Text,
	useBreakpointValue,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';

import AddToCart from '../AddToCart';
import { fetchAllProducts } from '../hooks/queries';
import useAuth from '../useAuth';

export const RelatedProductsCard = ({
	props,
	upsellData,
	relatedData,
}: RelatedProductsProps) => {
	const { isUnlicensedUser, loggedIn, isCustomerDefault } = useAuth();
	const [isOnSale] = useState(props.onSale);

	const allProducts = fetchAllProducts();

	const productData = useMemo(() => {
		if (!upsellData && !relatedData) {
			return [];
		}

		let combinedProducts = [...upsellData, ...relatedData].filter(
			(item: RelatedProductsProps) =>
				item.props?.stockStatus !== WpStockStatusEnum.OUT_OF_STOCK
		);

		if (isUnlicensedUser || !loggedIn || isCustomerDefault) {
			combinedProducts = combinedProducts.filter(
				(item: RelatedProductsProps) => {
					return item.props?.productTags?.every(
						(tag: any) =>
							tag.name !== 'Unlicensed' && tag.name !== 'Pharmaceuticals'
					);
				}
			);
		}

		if (combinedProducts.length < 8) {
			const numberOfProducts = 8 - combinedProducts.length;

			let randomProducts = [...allProducts];

			if (isUnlicensedUser || !loggedIn || isCustomerDefault) {
				randomProducts = randomProducts.filter((item: RelatedProductsProps) => {
					return item.props?.productTags?.every(
						(tag: any) =>
							tag.name !== 'Unlicensed' && tag.name !== 'Pharmaceuticals'
					);
				});
			}

			const hasDifferentSlug = (item: RelatedProductsProps) =>
				!combinedProducts.some((product) => product.slug === item.props.slug);

			randomProducts = randomProducts
				.filter(hasDifferentSlug)
				.slice(0, numberOfProducts);

			// if there is less than 8 upsell and related prodcuts, add random products
			combinedProducts = [...combinedProducts, ...randomProducts];
		}

		return combinedProducts.slice(0, 8);
	}, [
		upsellData,
		relatedData,
		isUnlicensedUser,
		loggedIn,
		isCustomerDefault,
		allProducts,
	]);

	return (
		<>
			<div className={styles.productcardRelated}>
				<Link
					href={`/${props.slug}`}
					_hover={{ textDecoration: 'none !important', color: '#df7c6c' }}>
					<Box
						position='relative'
						className={'image-container'}>
						{props.promotions?.onPromotion && (
							<Box
								position='absolute'
								zIndex='99'
								w='fit-content'
								bg='#F0FFF4'
								color='#39A26A'
								textTransform='uppercase'
								borderRadius='8px'
								px='8px'>
								Low Quantity
							</Box>
						)}
						{props.promotions?.additionalTag && (
							<Box
								position='absolute'
								zIndex='99'
								w='fit-content'
								bg='#FC8181'
								color='#fff'
								textTransform='uppercase'
								borderRadius='8px'
								px='8px'>
								{props.promotions?.additionalTag}
							</Box>
						)}

						<AspectRatio
							ratio={1 / 1}
							className='aspect-ratio'>
							<Image
								alt={props.image?.altText}
								className={styles.beloteroBalanceFront600x600Icon}
								src={props.image?.sourceUrl}
								draggable='false'
								fallback={<Skeleton />}
								borderRadius={useBreakpointValue({ base: 'md', md: 'xl' })}
							/>
						</AspectRatio>
					</Box>
				</Link>
				<div className={styles.productdetails}>
					<div className={styles.relatedproduct}>
						<Link
							href={`/${props.slug}`}
							_hover={{ textDecoration: 'none !important', color: '#df7c6c' }}>
							<b className={styles.productname}>{props.name}</b>
						</Link>

						<div className={styles.relatedprice}>
							{isOnSale ? (
								<>
									<Text
										className={styles.price1}
										marginTop='2 !important'
										_hover={{ textDecoration: 'none !important' }}>
										{' '}
										{props.price}{' '}
									</Text>
									<Text
										style={{ textDecoration: 'line-through' }}
										className={styles.discount}>
										{props.regularPrice}
									</Text>
								</>
							) : (
								<Text
									className={styles.price1}
									marginTop='2 !important'>
									{props.price}
								</Text>
							)}
						</div>
					</div>
				</div>

				<div className={styles.label}>
					{!loggedIn ? (
						<div
							className={styles.productcardButton}
							role='button'>
							<Link
								className='chakra-button css-1oloaee'
								href='/sign-in'>
								<Button
									variant='solid'
									colorScheme='#000'
									color='#000'
									size='0.75rem'>
									SIGN IN TO ORDER
								</Button>
							</Link>
						</div>
					) : (
						<div>
							<AddToCart
								productId={props.databaseId}
								name={props.name}
								price={props.price}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

enum WpStockStatusEnum {
	IN_STOCK = 'IN_STOCK',
	OUT_OF_STOCK = 'OUT_OF_STOCK',
	ON_BACKORDER = 'ON_BACKORDER',
}

type RelatedProductsProps = {
	props: {
		name: string;
		slug: string;
		price: string;
		regularPrice: string;
		onSale?: boolean;
		databaseId: number;
		stockStatus: WpStockStatusEnum;
		promotions: {
			onPromotion: boolean;
			additionalTag: string;
		};
		image?: {
			sourceUrl?: string;
			altText?: string;
		};
		productTags: {
			nodes?: { name: string };
		}[];
	};
	upsellData?: RelatedProductsProps[] | any;
	relatedData?: RelatedProductsProps[] | any;
};

export default RelatedProductsCard;
