import mediumZoom from 'medium-zoom';
import React from 'react'
import * as styles from "../product-single-page.module.css";

function ImageZoom({ zoom, src, alt, background }: any) {
  const zoomRef = React.useRef(zoom.clone({ background }))
  
  function attachZoom(image: any) {
    zoomRef.current.attach(image)
  }

  return <img src={src} alt={alt} ref={attachZoom}  />
}

export default ImageZoom
